@more-transition-speed: 3s;

.section-header-style() {
	text-transform: uppercase;
	color: @mah-grey-font;
	font-size: 10px;
	font-weight: bold;
	line-height: 24px;
}

.numerical() {
	color: @mah-primary-text-color;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
}

.section-padding() {
	padding: 24px 16px 0 72px;
}

.harbour-information {
	.zindex('harbour-information');
	padding: 0;
	background-color: @mah-white;
	max-height: 70vh;
	overflow-y: auto;

	&.more-inactive {
		.harbour-information-body {
			display: none;
		}
	}

	a, a:active, a:link, a:hover, a:focus {
		text-decoration: underline;
	}

	.harbour-information-payment-link, .harbour-information-services-link {
		background: none !important;
		outline: none !important;
		text-decoration: none !important;
		&:active, &:hover, &:focus, &:link {
			background: none !important;
			outline: none !important;
			text-decoration: none !important;
		}
	}

	.harbour-information-header {
		padding: 16px 16px;
		background-color: @mah-lighter-background;
		.flex-display();
		.flex-direction(row);
		.justify-content(space-between);
		.align-items(center);
		.harbour-information-basic {
			.flex-display();
			.flex-direction(row);
			min-width: 0;
		}
		.harbour-information-action {
			.align-self(flex-end);
		}
	}

	.harbour-information-body {
		padding: 0;

		.harbour-information-fit-warning {
			.flex-display();
			.flex-direction(row);
			.align-items(center);
			background-color: @mah-lighter-background;
			padding: 0 16px 16px 16px;
			.harbour-item-icon {
				border: none;
			}
			.harbour-information-fit-warning-text {
				color: @mah-red;
				padding-right: 40px;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.harbour-information-bookings-list {
			.flex-display();
			.flex-direction(column);
			.align-items(flex-start);
			background-color: @mah-lighter-background;
			padding: 0 16px 16px 16px;
			.harbour-information-booking-details {
				.flex-display();
				.flex-direction(row);
				.align-items(center);
				&:not(:last-child) {
					margin-bottom: 28px;
				}
				.booking-item-icon {
					margin-right: 20px;
				}
				.booking-time {
					.booking-time-title {
						text-transform: uppercase;
						font-size: 11px;
						color: @mah-grey-font;
						font-weight: 500;
					}
					.booking-time-date {
						font-size: 14px;
						font-weight: 500;
					}
					&:not(:last-child) {
						margin-right: 40px;
					}
				}
			}
		}

		.harbour-information-body-section {
			.section-padding();
			.harbour-information-body-section-header {
				.section-header-style();
			}
			.harbour-information-body-section-content {
				color: rgba(54, 73, 112, 0.87);
				font-size: 13px;
				line-height: 16px;
			}
		}

		.harbour-information-payment-section {
			.flex-display();
			.flex-direction(row);
			.justify-content(space-between);
			.align-items(center);

			background-color: @mah-lighter-background;
			padding: 16px 16px 16px 72px;
			.harbour-information-payment-section-main {
				.flex-display();
				.flex-direction(column);
				.harbour-information-payment-section-main-price {
					.numerical();
				}

				.harbour-information-payment-section-main-header {
					.section-header-style();
				}
			}

		}

		.harbour-information-services-section-content {
			.flex-display();
			.flex-direction(row);
			.justify-content(space-between);
			.align-items(center);
			.harbour-information-services-section-icons {
				max-width: 100%;
				text-overflow: clip;
				white-space: nowrap;
				overflow: hidden;
				img {
					width: 44px;
					max-width: 44px;
				}
			}
		}

		.harbour-information-berth-information {
			.flex-display();
			.flex-direction(row);
			.align-items(center);
			padding-top: 12px;
			.harbour-information-body-sub-section {
				margin-right: 72px;
			}
			.harbour-information-body-section-header {
				.section-header-style();
			}
			.harbour-information-sub-section-data {
				.numerical();
			}
		}

		.harbour-information-body-contact {
			background-color: @mah-theme-color;
			color: @mah-white;
			margin: 16px 0 0 0;
			padding-bottom: 16px;
			.harbour-information-body-contact-header {
				.section-header-style();
				.section-padding();
			}
			.harbour-information-body-contact-sub-section {
				.flex-display();
				.flex-direction(row);
				.align-items(center);
				padding-right: 16px;

				.harbour-item-icon {
					border: none;
					margin-left: 12px;
				}

				.harbour-information-body-contact-detail {
					max-width: 100%;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					a, a:active, a:link, a:hover, a:focus {
						text-decoration: none;
						color: @mah-white;
					}
				}
			}
		}
	}
}