@booking-icon-size: 20px;

.booking-item-icon {
	margin-right: 16px;
	padding: 8px;
	img {
		width: @booking-icon-size;
		max-width: @booking-icon-size;
		height: @booking-icon-size;
		max-height: @booking-icon-size;
	}
}