.cards-list-menu, .order-payment-menu-button {
	.credit-card-item {
		width: 100%;
		a {
			padding: 3px 10px;
			.flex-display(flex);
			.flex-direction(row);
			.justify-content(space-between);
			text-decoration: none;
			.credit-card-icon {
				img {
					.fixed-size-image(28px, 19px);
				}
			}
			&:hover, &:link, &:active, &:visited {
				color: @mah-primary-text-color;
			}
		}
		.add-card-link {
			span {
				float: left;
			}
			i {
				float: right;
			}
		}
		.credit-card-number {
			font-weight: normal;
		}
	}
}

.order-payment-menu-button {
	.credit-card-item {
		a {
			padding: 3px 0;
		}
	}
}