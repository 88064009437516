.page-with-menu .menu-container .menu-top-part {
	.menu-vessels {
		margin: 18px 0 0 0;
		.menu-vessels-topic {
			margin-left: 15px;
			font-size: @reduced-font-size;
			color: @mah-secondary-font;
		}
		.menu-vessels-menu {
			.vessel-menu-group {
				margin: 0;
				.flex-display(flex);
				.flex-direction(column);
				.vessel-menu-button {
					width: 100%;
					padding: 4px 15px 10px;
					background: none;
					font-weight: normal;
					font-size: @default-font-size;
					text-transform: none;
					.flex-display(flex);
					.justify-content(space-between);
					&:focus, &:hover, &:active {
						outline: none;
					}
					.vessel-dropdown-current-value {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.vessels-list-menu {
					width: 100%;
					right: 0;
					float: none;
					background-color: @mah-theme-color;
					height: ~"calc(100vh - @{menu-top-part-height})";
					max-height: ~"calc(100vh - @{menu-top-part-height})";
					overflow-y: auto;
					padding-bottom: 16px;
					&.activated {
						display: block;
					}
					.vessel-list-item {
						.vessel-item-icon {
							display: inline-block;
							margin-right: 12px;
							img {
								.square-image(33px);
							}
						}
						.vessel-list-item-name {
							max-width: 100%;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
						a {
							color: @mah-white;
							padding-left: 8px;
							.flex-display(flex);
							.align-items(center);
						}
						.add-vessel-link {
							margin-left: 8px;
							span {
								float: left;
							}
							i {
								float: right;
							}
						}
					}
				}
			}
		}
	}
}