@forgot-password-margin-top: 52px;

.forgot-password {
	.forgot-password-header {
		.zindex('forgot-password-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @forgot-password-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.forgot-password-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.forgot-password-form {
		.zindex('forgot-password-form');
		position: absolute;
		top: @items-list-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{items-list-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;
		.forgot-password-description {
			font-size: 12px;
			color: @mah-theme-color-darker;
		}
		.forgot-password-email {
			margin-top: 12px;
		}
		.forgot-password-buttons {
			margin-top: 0;
		}
	}
}