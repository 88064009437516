@items-list-margin-top: 52px;

.items-list {
	.items-list-header {
		.zindex('items-list-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @items-list-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.items-list-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.add-new-list-item {
		.zindex('add-new-list-item');
		position: absolute;
		bottom: 16px;
		right: 16px;

		.add-new-icon {
			.flex-display(flex);
			.align-items(center);
			.justify-content(center);
			background-color: @mah-red;
			border: 0;
			.shadowed(8px);
			.border-radius(56px/2);
			height: 56px;
			width: 56px;
		}
	}

	.items-list-items {
		.zindex('items-list-items');
		position: absolute;
		top: @items-list-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{items-list-margin-top})";
		background: @mah-light-grey-background;
		overflow-y: auto;
		.items-list-list-items {
			margin: 8px 8px 0 8px;
			padding: 0;
			list-style: none;
			.flex-display(flex);
			.flex-direction(column);
		}
	}
}