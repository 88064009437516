@harbour-icon-size: 20px;

.harbour-item-icon {
	margin-right: 16px;
	padding: 8px;
	border: 2px dotted @mah-theme-color;
	.border-radius(@harbour-icon-size);
	img {
		width: @harbour-icon-size;
		max-width: @harbour-icon-size;
		height: @harbour-icon-size;
		max-height: @harbour-icon-size;
	}
}