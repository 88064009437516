@about-information-margin-top: 52px;

.section-header-style() {
	text-transform: uppercase;
	color: @mah-grey-font;
	font-size: 10px;
	font-weight: bold;
	line-height: 24px;
}

.about-information {
	.about-information-header {
		.zindex('about-information-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @about-information-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.about-information-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.about-information-details {
		.zindex('about-information-details');
		position: absolute;
		top: @about-information-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{about-information-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;
		padding-top: 8px;
		.about-information-item {
			padding: 8px 8px 8px 20px;
			font-weight: 400;
			a, a:active, a:hover, a:link {
				color: @mah-primary-text-color;
				text-decoration: none;
			}
		}
		.about-information-item-spacer {
			padding: 12px 8px 12px 20px;
		}
		.about-information-item-header {
			.section-header-style();
		}
	}
}