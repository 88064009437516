@vessel-edit-margin-top: 52px;

.vessel-edit {
	.vessel-edit-header {
		.zindex('vessel-edit-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @vessel-edit-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.vessel-edit-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.vessel-edit-form {
		.zindex('vessel-edit-form');
		position: absolute;
		top: @items-list-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{items-list-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;

	}
}