.form-control,
.form-group .form-control {
	&[readonly],
	&[disabled],
	fieldset[disabled] & {
		color: @mah-inactive-font;
	}
}

.form-group.with-checkbox {
	margin-top: 0;
}

.checkbox-label {
	font-size: 65%;
	padding-left: 8px;
}