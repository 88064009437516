@password-recovery-margin-top: 52px;

.password-recovery {

	.password-recovery-loading {
		display: none;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
	}

	&.loading {
		.password-recovery-loading {
			display: block;
		}
		.password-recovery-header, .password-recovery-form {
			display: none;
		}
	}

	.password-recovery-header {
		.zindex('password-recovery-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @password-recovery-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.password-recovery-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.password-recovery-form {
		.zindex('password-recovery-form');
		position: absolute;
		top: @items-list-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{items-list-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;
		.password-recovery-description {
			font-size: 12px;
			color: @mah-theme-color-darker;
		}
		.password-recovery-field {
			margin-top: 12px;
		}
		.password-recovery-buttons {
			margin-top: 0;
		}
	}
}