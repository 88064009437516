.edit-password-modal {
	margin: 24px 24px 8px 24px;
	width: 80vw;
	.edit-password-modal-header {
		color: @mah-field-basic-color;
		font-size: 20px;
		font-weight: 400;
	}
	.edit-password-modal-body {
		margin: 0 16px;
		.form-group {
			padding-right: 0;
			padding-left: 0;
		}
		.edit-password-buttons {
			text-align: right;
		}
		.errors-text, .errors-icon {
			padding-left: 0;
			padding-right: 0;
		}
	}
}