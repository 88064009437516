@import (inline) '../../node_modules/react-day-picker/lib/style.css';

@date-picker-selected-color: #3b7cff;
@selected-date-border-radius: 24px;

.DayPicker {

	.DayPicker-NavBar {
		padding: 0.1rem .5rem 0;
	}

	.DayPicker-Month {
		width: 95%;
		margin: 0;

		.DayPicker-Caption {
			height: 3rem;
			font-size: 0.9em;
			font-weight: 500;
		}

		.DayPicker-Weekday {
			abbr[title] {
				border: none;
				color: @mah-label-inactive;
				font-weight: 500;
				font-size: 0.8em;
			}
		}

		.DayPicker-Body {

			.DayPicker-Day {
				font-size: 0.8em;
				font-weight: 500;
				border: none;
				padding: 1.1rem .5rem;
			}

			.DayPicker-Day--outside {
				color: inherit;
			}

			.DayPicker-Day--disabled {
				background: none;
				color: #dce0e0;
			}

			.DayPicker-Day--selected {
				color: @date-picker-selected-color;
				background: fade(@date-picker-selected-color, 20%);
				&:first-of-type {
					.border-left-radius(@selected-date-border-radius);
				}
				&:last-of-type {
					.border-right-radius(@selected-date-border-radius);
				}
			}

			.DayPicker-Day--first-selected-day {
				.border-left-radius(@selected-date-border-radius);
			}

			.DayPicker-Day--last-selected-day {
				&:not(.DayPicker-Day--first-selected-day) {
					.border-right-radius(@selected-date-border-radius);
				}
				&.DayPicker-Day--first-selected-day {
					.border-radius(@selected-date-border-radius);
				}
			}

			.DayPicker-Day--today {
				color: @date-picker-selected-color;
			}
		}
	}
}