@static-content-margin-top: 52px;

.static-content {
	.static-content-header {
		.zindex('static-content-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @static-content-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.static-content-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.static-content-details {
		.zindex('static-content-details');
		position: absolute;
		top: @static-content-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{static-content-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;
		padding: 8px 12px 0 12px;
		h4 {
			margin-top: 4px;
			font-weight: 400;
		}
		ol {
			padding-left: 0;
			list-style-position: inside;
			counter-reset: item;

			> li {
				padding: 6px 0;
				font-weight: 100;
				display: block;
				&.list-major {
					padding: 10px 0;
					> span {
						font-weight: 400;
					}
				}
				&:before {
					content: counters(item, ".") ".";
					counter-increment: item;
					margin-right: 6px;
				}
			}

			ul {
				list-style-position: outside;
				list-style-type: disc;
			}
		}
	}
}