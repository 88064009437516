@harbours-list-margin-top: 52px;

.harbours-list, .harbours-search {
	.harbours-list-header, .harbours-search-header {
		.zindex('harbours-list-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);
		.justify-content(space-between);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @harbours-list-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		.harbours-list-header-right {
			margin-right: 4px;
		}

		.harbours-list-header-left, .harbours-search-header-left {
			.flex-display();
			.flex-wrap(nowrap);
		}

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.back-button, .search-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.harbours-list-title, .harbours-search-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.harbours-list-items, .harbours-search-items {
		.zindex('harbours-list-items');
		position: absolute;
		top: @harbours-list-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{harbours-list-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;
		.harbours-items {
			margin: 0 16px 8px;
			padding: 0;
			list-style: none;
			.flex-display(flex);
			.flex-direction(column);
			.harbours-items-group {
				font-weight: bold;
				font-size: 10px;
				color: @mah-inactive-font;
				margin: 24px 0 0 0;
				&.no-items {
					display: none;
				}
			}
		}
	}
}