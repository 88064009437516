@expanded-drop-down-height: 30vh;

.generic-modal-window .generic-modal-window-body {
	&.order-window-body {
		margin: 0;
	}
	&.loading {
		.order-data-fields, .order-amount-details, .order-actions, .order-payment-methods, .order-agreement-details {
			display: none;
		}
	}
	&.submit-in-progress {
		.order-data-fields, .order-amount-details, .order-actions, .order-payment-methods, .order-agreement-details {
			display: block;
			opacity: 0.2;
		}
		.row {
			visibility: visible;
		}
	}
}

.order-data-fields, .order-amount-details, .order-actions, .order-payment-methods, .order-agreement-details {
	padding: 0 24px;
}

.order-data-fields {
	background-color: @mah-white;
	font-size: 14px;
}

.order-data-field {
	margin: 14px 0;
	font-weight: 500;

	.order-data-field-header {
		text-transform: uppercase;
		font-size: 11px;
		color: @mah-grey-font;
	}
}

.order-payment-methods {
	.flex-display(flex);
	.flex-direction(column);
	&.drop-down-activated {
		height: @expanded-drop-down-height;
		min-height: @expanded-drop-down-height;
	}
	.order-payment-menu-button {
		width: 100%;
		padding: 8px 0 0;
		background: none;
		border: none;
		font-weight: normal;
		font-size: @reduced-font-size;
		text-transform: none;
		.flex-display(flex);
		.justify-content(space-between);
		&:focus, &:hover, &:active {
			outline: none;
		}
	}

	.cards-list-menu {
		margin: 0 12px;
		right: 0;
		top: 52px;
		float: none;
		color: @mah-primary-text-color;
		height: @expanded-drop-down-height;
		max-height: @expanded-drop-down-height;
		overflow-y: auto;
		padding-bottom: 4px;
		border: none;
		box-shadow: 1px 1px 1px 1px @mah-inactive-background-darker;
		&.activated {
			display: block;
		}
	}

	.order-data-fields-separator {
		margin-top: 12px;
		border-bottom: 2px solid @mah-inactive-background-darker;
	}
}