@harbour-services-information-margin-top: 52px;

.harbour-services-information {
	.harbour-services-information-header {
		.zindex('harbour-services-information-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @harbour-services-information-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.harbour-services-information-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.harbour-services-information-list {
		.zindex('harbour-services-information-list');
		position: absolute;
		top: @harbour-services-information-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{harbour-services-information-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;
		padding-top: 8px;
		.harbour-services-information-item {
			.flex-display();
			.flex-direction(row);
			.align-items(center);
			padding: 8px;
			.harbour-services-information-item-icon {
				img {
					width: 44px;
					max-width: 44px;
				}
			}
			.harbour-services-information-item-text {
				margin-left: 16px;
				font-weight: 400;
				text-transform: capitalize;
			}
		}
	}
}