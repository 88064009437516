.generic-modal-window {
	margin: 0;
	width: 80vw;
	.flex-display();
	.flex-direction(column);
	.align-items(stretch);
	.align-content(center);
	.align-self(flex-start);

	.generic-modal-window-header-container {
		background-color: @mah-background-darker-color;
		width: 100%;
		.flex-display();
		.flex-direction(column);
		.align-items(center);
		.align-content(center);
		.generic-modal-window-header-content {
			padding: 16px 0 8px 18px;
			.align-self(flex-start);
			font-size: 18px;
			font-weight: 400;
			color: @mah-primary-text-color;
		}
	}

	.generic-modal-window-body {
		margin: 0 24px;
		width: auto;
		.form-group {
			padding-right: 0;
			padding-left: 0;
		}
		.generic-modal-window-buttons {
			text-align: right;
		}
		.errors-text, .errors-icon {
			padding-left: 0;
			padding-right: 0;
		}

		.generic-modal-window-body-loading {
			display: none;
			margin: 0 auto;
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			text-align: center;
		}

		&.loading {
			.generic-modal-window-body-loading {
				display: block;
			}

			.row {
				visibility: hidden;
			}
		}

	}
}