.generic-modal-window .generic-modal-window-header-container {
	.check-out-modal-counter-controls {
		.flex-display();
		.flex-direction(row);
		.align-items(center);
		.align-content(center);
		.justify-content(center);
		.check-out-modal-counter-controls-value {
			color: @mah-theme-color;
			font-size: 36px;
			margin: 0 16px;
			font-weight: 400;
		}
	}
	.check-out-modal-counter-controls-description {
		padding: 0 0 8px;
		font-size: 10px;
		font-weight: 400;
		color: @mah-grey-font;
		text-transform: uppercase;
	}
}