.order-amount-details {
	font-size: 14px;
	margin: 14px 0 0;

	.order-total-clarification {
		.order-total-text {
			font-size: 16px;
			font-weight: 500;
		}

		.order-total-text-small {
			font-size: 12px;
			color: @mah-theme-color-darker;
		}

		.order-total-text-small-bolder {
			font-size: 10px;
			color: @mah-theme-color-darker;
			font-weight: 500;
		}
	}

	.order-amount-price {
		color: @mah-theme-color-darker;
		font-weight: 500;
		font-size: 20px;
		text-align: right;
	}
}