body {
	&.mah-home-loading {
		.flex-display();
		.flex-direction(column);
		.justify-content(center);
		height: 100vh;
		.mah-home-loading-image {
			display: block;
			margin: 0 auto;
		}
		#mah-root {
			display: none;
		}
	}
	.mah-home-loading-image {
		display: none;
	}
}