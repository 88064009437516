.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.border-radius (@radius: 0.3125em) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;

	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}

.border-left-radius (@radius: 0.3125em) {
	-webkit-border-bottom-left-radius: @radius;
	-moz-border-radius-bottomleft: @radius;
	border-bottom-left-radius: @radius;
	-webkit-border-top-left-radius: @radius;
	-moz-border-radius-topleft: @radius;
	border-top-left-radius: @radius;

	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}

.border-right-radius (@radius: 0.3125em) {
	-webkit-border-bottom-right-radius: @radius;
	-moz-border-radius-bottomright: @radius;
	border-bottom-right-radius: @radius;
	-webkit-border-top-right-radius: @radius;
	-moz-border-radius-topright: @radius;
	border-top-right-radius: @radius;

	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}

.transition (@transition) {
	-webkit-transition: @transition;
	-moz-transition: @transition;
	-ms-transition: @transition;
	-o-transition: @transition;
}

.shadowed(@radius: 0.125em, @color-primary: @mah-inactive-background-darker, @color-secondary: @mah-inactive-background) {
	box-shadow: 0 @radius @radius 0 @color-primary, 0 0 @radius 0 @color-secondary;
}

.placeholder-color(@color) {
	&::-webkit-input-placeholder {
		color: @color;
	}

	&:-moz-placeholder {
		color: @color;
	}

	&::-moz-placeholder {
		color: @color;
	}

	&:-ms-input-placeholder {
		color: @color;
	}
}

.opacity(@opacity: 0.5) {
	-webkit-opacity: @opacity;
	-moz-opacity: @opacity;
	opacity: @opacity;
}

.square-image(@size) {
	width: @size;
	min-width: @size;
	max-width: @size;
	height: @size;
	max-height: @size;
	min-height: @size;
}

.fixed-size-image(@width, @height) {
	width: @width;
	min-width: @width;
	max-width: @width;
	height: @height;
	max-height: @height;
	min-height: @height;
}