.add-credit-card-modal {
	margin: 0;
	width: 80vw;
	.add-credit-card-modal-header {
		color: @mah-field-basic-color;
		font-size: 20px;
		font-weight: 400;
		padding: 0 24px;
		background-color: @mah-lighter-background;
		height: 48px;
		.flex-display();
		.justify-content(flex-start);
		.align-items(center);
		div {
			.align-self(center);
		}
	}

	.add-credit-card-modal-body {
		margin: 0 32px 8px 32px;
		padding: 0;

		.form-group {
			padding-right: 4px;
			padding-left: 4px;
		}

		.add-credit-card-buttons {
			text-align: right;
		}
		.errors-text, .errors-icon {
			padding-left: 0;
			padding-right: 0;
		}

		.add-credit-card-loading {
			display: none;
			margin: 0 auto;
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			text-align: center;
		}

		&.loading {
			.add-credit-card-loading {
				display: block;
			}

			.row {
				visibility: hidden;
			}
		}
	}
}