@menu-width: 100vw;
@menu-margin: 17vw;
@transition-speed: 0.2s;
@menu-top-part-height: 172px;

.page-with-menu {
	position: absolute;
	left: 0;
	top: 0;

	&.menu-active {
		.page-menu {
			left: 0;
		}
	}

	.page-menu, .page-element {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
	}

	.page-menu {
		.zindex('page-menu');
		width: @menu-width;
		left: -@menu-width;
		.transition(~"left @{transition-speed} ease, width @{transition-speed} ease");
		.flex-display();
	}

	.page-element {
		width: 100vw;
		overflow-x: hidden;
		.transition(~"left @{transition-speed} ease, width @{transition-speed} ease");
	}

	.menu-container {
		width: 100vw - @menu-margin;
		background-color: @mah-theme-color;
		height: 100vh;
		.shadowed(16px);
		.flex-display(flex);
		.flex-direction(column);
		.menu-top-part {
			background-color: @mah-inactive-background-darker;
			height: @menu-top-part-height;
			.menu-icon-keeper {
				.menu-logo {
					margin: 36px 0 0 12px;
				}
			}
		}
	}

	.menu-margin {
		width: @menu-margin;
		background-color: @mah-transparent-background;
		height: 100vh;
	}
}