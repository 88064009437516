.invoice-details {
	&.not-available {
		display: none;
	}

	&.data-loaded {
		position: absolute;
		top: 0;
		left: 0;
	}

	.invoice-details-loading {
		display: none;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
	}

	&.loading {
		.invoice-details-loading {
			display: block;
		}
		.invoice-details-data {
			display: none;
		}
	}

	@invoice-print-con-marging: 24px;

	.invoice-details-print {
		position: fixed;
		top: @invoice-print-con-marging;
		left: @invoice-print-con-marging;
		width: 100vw;
		z-index: 2;
		img {
			cursor: pointer;
		}
	}

	@media print {
		.invoice-details-print {
			display: none;
		}
	}
}