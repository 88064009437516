.authorization-component {
	.logo-holder {
		margin: 66px 0 38px 0;
		img {
			margin: 0 auto;
			display: block;
		}
	}
	.auth-navigation {
		font-weight: 500;

		@media (min-width: 0px) {
			.nav-justified > li {
				display: table-cell;
				width: 1%;
				> a {
					margin-bottom: 0;
				}
			}

			.nav-pills {
				> li {
					border-bottom: 2px solid @mah-inactive-background;

					a {
						padding-bottom: 6px;
						color: @mah-inactive-font;
					}

					// Active state
					&.active {
						border-bottom: 2px solid @mah-theme-color;
						> a {
							&,
							&:hover,
							&:focus {
								color: @mah-theme-color;
								background-color: @mah-white;
							}
						}
					}
				}
			}
		}
	}

	.social-login-loading {
		display: none;
		margin: 48px auto;
	}

	.forgot-password {
		margin: 0 auto;
		text-align: center;
		font-size: 14px;
		a, a:hover, a:link, a:active, a:visited {
			color: @mah-field-basic-color;
		}
	}

	&.loading {
		.social-login-loading {
			display: block;
		}
		.auth-form, .social-login-buttons {
			display: none;
		}
		.forgot-password {
			display: none;
		}
	}
}