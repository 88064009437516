@z-index-order: 'google-map-placeholder', 'google-map', 'items-list-items', 'harbours-list-items',
'vessel-edit-form', 'forgot-password-form', 'password-recovery-form', 'home-search-menu', 'items-list-header',
'harbours-list-header', 'vessel-edit-header', 'forgot-password-header', 'password-recovery-header', 'harbour-services-information-header',
'harbour-services-information-list', 'about-information-header', 'about-information-details',
'static-content-header', 'static-content-details',
'account-edit-header', 'account-edit-form',
'harbours-list', 'harbour-information', 'locate-me',
'add-new-list-item', 'page-menu', 'auto-complete-list',
'modal-over-page';

.zindex(@elementName) {
	.loop(@elementName, @counter) when (@counter > 0) {
		.loop(@elementName, @counter - 1);
		.pickIndex(@elementName, @counter);

	}
	.loop(@elementName, length(@z-index-order));
	.pickIndex(@elementName, @counter) when (extract(@z-index-order, @counter) = @elementName) {
		z-index: @counter * 100;
	}
}