.no-vessel-warning-modal {
	margin: 0;
	width: 80vw;
	.align-self(flex-start);

	.no-vessel-warning-modal-header {
		color: @mah-field-basic-color;
		font-size: 20px;
		font-weight: 400;
		padding: 0 24px;
		background-color: @mah-lighter-background;
		height: 48px;
		.flex-display();
		.justify-content(flex-start);
		.align-items(center);
		div {
			.align-self(center);
		}
	}

	.no-vessel-warning-modal-body {
		margin: 0 32px 8px 32px;
		padding: 0;

		.no-vessel-warning-action-buttons {
			text-align: right;
		}
	}
}