@items-list-single-item-height: 136px;
@items-list-single-item-width: 88px;
@items-list-single-item-border-radius: 2px;
@items-list-single-item-margin: 8px;

.items-list-single-item {
	list-style: none;
	.flex-display(flex);
	.flex-direction(row);

	height: @items-list-single-item-height;
	min-height: @items-list-single-item-height;
	max-height: @items-list-single-item-height;
	.border-radius(@items-list-single-item-border-radius);
	background: @mah-white;
	.shadowed(@items-list-single-item-border-radius);

	margin: 0 0 @items-list-single-item-margin 0;

	.items-list-single-item-icon {
		background: @mah-inactive-background-lighter;
		width: @items-list-single-item-width;
		.flex-display();
		.flex-direction(column);
		.flex-wrap(nowrap);
		.align-items(center);
		.justify-content(center);
		.border-left-radius(@items-list-single-item-border-radius);
	}

	.items-list-single-item-details {
		.flex-display(flex);
		.flex-direction(column);
		margin: 0;
		padding: 10px 0 0 0;
		max-width: ~"calc(100% - @{items-list-single-item-width})";
		width: ~"calc(100% - @{items-list-single-item-width})";

		.items-list-single-item-description {
			.flex-display(flex);
			.flex-direction(column);
			.flex-grow(4);
			margin: 0;
			padding: 0 @items-list-single-item-margin 0 18px;

			.items-list-single-item-name {
				font-size: 24px;
				color: @mah-primary-text-color;
				font-weight: 400;
			}

			.items-list-single-item-type, .items-list-single-item-name {
				max-width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.items-list-single-item-actions {
			.flex-grow(2);
			margin: 0;
			padding: 10px @items-list-single-item-margin 0 6px;
			border-top: 1px solid @mah-inactive-background-lighter;

			a {
				padding: 8px 12px;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
			}

			.edit-items-list-single-item {
				&:active, &:focus, &:hover, &:link {
					color: @brand-primary;
				}
			}

			.remove-items-list-single-item {
				&:active, &:focus, &:hover, &:link {
					color: @brand-danger;
				}
			}
		}
	}
}