.page-with-menu .menu-container .menu-items {
	.menu-item {
		padding: 16px 0 16px 16px;

		&:hover, &:active, &:focus {
			background-color: @mah-inactive-background;
		}

		a, a:hover, a:link, a:focus, a:active {
			color: @mah-white;
			text-decoration: none;
			.flex-display(flex);
			.flex-wrap(nowrap);
			.align-items(center);
			p {
				font-size: @reduced-font-size;
				padding: 0;
				margin: 4px 0 0 0;
			}
		}

		.material-icons {
			margin-right: 32px;
		}
	}
}