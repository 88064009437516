.component-with-modal {

	.with-modal-main-content,
	.with-modal-overlay-content {
		.transition(~"opacity 0.3s ease");
	}

	.with-modal-overlay-content {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		overflow: hidden;
		background: @mah-dark-background;

		.flex-display();
		.justify-content(center);
		.align-items(center);

		.modal-window-data {
			max-height: 80vh;
			max-width: 90vw;
			overflow: auto;
			background: @mah-white;
			.shadowed();
			.border-radius(2px);
			.flex-display();
			.justify-content(center);
			.align-items(center);
		}
	}

	&.modal-activated {
		.with-modal-main-content {
			.opacity(0.7);
		}

		.with-modal-overlay-content {
			.opacity(1);
			.zindex('modal-over-page');
			.modal-window-data {
				min-height: 16vh;
				min-width: 50vw;
				padding: 0;

				&.small-modal {
					min-height: inherit;
				}
			}
		}
	}

	.with-modal-main-content {
		.opacity(1);
	}

	.with-modal-overlay-content {
		.opacity(0);
		.modal-window-data {
			min-height: 0;
			min-width: 0;
			padding: 0;
		}
	}
}