.page-with-menu .menu-container .menu-items {
	height: ~"calc(100vh - @{menu-top-part-height})";
	max-height: ~"calc(100vh - @{menu-top-part-height})";
	overflow-y: auto;
	.flex-display(flex);
	.flex-direction(column);

	list-style: none;
	padding: 0;
	margin: 8px 0 0 0;

	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}