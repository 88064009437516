@harbour-item-height: 72px;

.harbour-item {
	list-style: none;
	.flex-display(flex);
	.flex-direction(row);

	height: @harbour-item-height;
	min-height: @harbour-item-height;
	max-height: @harbour-item-height;

	margin: 0;

	.harbour-item-link {
		.flex-display(flex);
		width: 100%;
		.align-items(center);
		color: @mah-primary-text-color;
		text-decoration: none;
		&:hover, &:active, &:link, &:focus, &:visited {
			color: @mah-primary-text-color;
			text-decoration: none;
		}
	}
}

.harbour-item-details {
	.flex-display(flex);
	.flex-direction(column);
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	.harbour-item-name {
		font-size: 18px;
		color: @mah-primary-text-color;
		font-weight: 400;
	}

	.harbour-item-type {
		font-size: 14px;
		color: @mah-secondary-text-color;
		font-weight: 400;
	}

	.harbour-item-type, .harbour-item-name {
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}