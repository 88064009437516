.form-group input, .form-group select {
	font-weight: 400;
}

.form-group {
	&.black-readonly {
		.form-control[readonly] {
			color: inherit;
		}
	}
}

.middle-aligned {
	display: inline-block;
	vertical-align: middle;
}