.btn,
.input-group-btn .btn {
	background-color: @mah-inactive-background;
	color: @mah-background-color;
	&:not(.btn-raised), &:not(.btn-social) {
		.variations(~".btn", ~"", color, @mah-white);
		box-shadow: none;

		&.btn-wout-back {
			background: none !important;
			padding-left: 16px;
			padding-right: 16px;
			color: @mah-inactive-font;
			&.available {
				color: @mah-theme-color;
			}
		}

		&.btn-main-action {
			.border-radius(24px);
			padding-left: 24px;
			padding-right: 24px;
			color: @mah-white;
			background: @mah-inactive-background-lighter !important;
			&.available {
				background: @mah-active-button-backgroud !important;;
			}
		}

		&.btn-inverse {
			background-color: @mah-background-color;
			color: @mah-theme-color;
			.shadowed();
		}

		&:not(.btn-link):not(.btn-wout-back):not(.btn-main-action) {
			&:hover,
			&:focus,
			&.available {
				background-color: @mah-theme-color;

				.theme-dark & {
					background-color: @mah-theme-color;
				}

				&.btn-inverse {
					background-color: @mah-background-color;
				}

				&.btn-social {
					background-color: @mah-social-background;
				}
			}
		}

		&.red-button {
			background-color: @mah-red;
			&:hover,
			&:focus {
				background-color: @mah-red;
			}
		}
	}

	fieldset[disabled][disabled] &,
	&.disabled,
	&:disabled,
	&[disabled][disabled] {
		color: @mah-background-color;
		background: @mah-inactive-background;
	}

	&.btn-social {
		color: @mah-primary-text-color;
		text-align: left;
		background-color: @mah-social-background;
		text-transform: none;
		font-weight: normal;
		padding: 12px 30px 12px 16px;
		.box-shadow(0 1px 2px @mah-inactive-background-darker);
		font-size: 80%;
	}
}

label.control-label {
	left: auto !important;
}

.form-group {
	&.is-empty {
		label,
		label.control-label {
			color: @mah-label-inactive;
		}
	}
	&.is-focused {
		label,
		label.control-label {
			color: @mah-theme-color;
		}
	}

	.auto-complete-items {
		display: none;
	}

	&.auto-complete-items {
		.auto-complete-items {
			display: block;
			position: absolute;
			left: 0;
			.zindex('auto-complete-list');

			ul {
				list-style: none;
				max-height: 4em;
				overflow-y: auto;
				margin: 0;
				padding: 0 0 0 2px;
				background: @mah-white;
				.border-radius(2px);
				.shadowed(2px);
				li a {
					display: block;
					color: @mah-primary-text-color;
					&:active, &:hover, &:focus, &:link, &:visited {
						text-decoration: none;
					}
					&:active, &:hover, &:focus {
						background: @mah-inactive-background-lighter;
					}
				}
			}
		}
	}

	.errors-text, .errors-icon {
		margin-top: 0;
		font-size: @help-block-font-size;
		position: absolute;
		display: none;
		color: @mah-error;
	}

	.errors-text {
		left: 0;
		max-width: ~"calc(100% - 18px)";
		overflow-x: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.errors-icon {
		right: 0;
		text-align: right;
	}

	&.has-mah-error {

		.errors-text, .errors-icon {
			display: block;
		}

		.form-control {
			background-image: linear-gradient(@mah-error, @mah-error), linear-gradient(@mah-error, @mah-error);
			background-size: 0 2px, 100% 1px;
		}
	}
}

#mah-root {
	.checkbox, label.checkbox-inline {
		.checkbox-material {
			.check {
				border-radius: 0;
				&:before {
					margin-top: -6px;
					margin-left: 5px;
				}
			}
		}
	}
}