.activation-failed-section {
	margin: 16px auto 0;

	.after-activation-instructions {
		margin-top: 32px;
		font-size: @reduced-font-size;
		color: @mah-secondary-text-color;
	}

	.activation-failed-form {
		margin: 0 24px;
		.form-group {
			padding-right: 0;
			padding-left: 0;
		}
		.check-in-buttons {
			text-align: right;
		}
		.errors-text, .errors-icon {
			padding-left: 0;
			padding-right: 0;
		}
	}
}