.activation-ok {
	&.not-available {
		display: none;
	}
	.footer-with-button {
		margin-top: 172px;
		&.no-top-margin {
			margin-top: 16px;
		}
	}
}