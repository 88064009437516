.registration-ok {
	.registration-information-section {
		margin: 184px auto 0;

		.after-registration-instructions {
			margin-top: 48px;
			font-size: @reduced-font-size;
			color: @mah-secondary-text-color;
		}
		.registration-confirmation-sent-to {
			margin-top: 26px;
			font-size: @default-font-size;
		}
	}
	.footer-with-button {
		margin-top: 172px;
	}
}