.user-home {
	position: relative;
	height: 100vh;
	.flex-display();
	.flex-direction(column);

	.home-search-menu {
		.zindex('home-search-menu');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.home-search {
			.flex-display();
			.align-items(center);
			.border-radius(2px);
			.flex-direction(row);
			.flex-wrap(nowrap);

			margin: 7px 12px;
			background: @mah-theme-color;
			color: @mah-light-white;
			min-height: 52px;
			.shadowed();

			a, a:active, a:hover, a:link {
				color: @mah-light-white;
				text-decoration: none;
				cursor: text;
			}

			.menu-button {
				background-color: @mah-theme-color;
				border: 0;
				padding: 0;
				margin: 0 14px;
				.flex-display(flex);
			}

			.search-action {
				margin: 0 0 0 12px;
			}
		}
	}

	.harbours-list {
		.zindex('harbours-list');
		padding: 24px 16px;
		background-color: @mah-background-darker-color;
		text-align: left;
		border-width: 0;
		.shadowed();

		color: @mah-theme-color;
		font-weight: 500;
		line-height: 1em;
	}

	.locate-me-icon {
		cursor: pointer;
		margin: 0 16px 8px 0;
		.shadowed(8px);
		.border-radius(56px/2);
		height: 56px;
		width: 56px;

		background: url("/img/icons/location.png") center no-repeat @mah-white;

		@media (-webkit-min-device-pixel-ratio: 2),
		(min-resolution: 192dpi) {
			background: url("/img/icons/location@2x.png") center no-repeat @mah-white;
			background-size: 22px 22px;
		}
	}

	.google-map {
		.zindex('google-map');
		.flex(1);
		.flex-order(-1);
		position: relative;

		#google-map-container {
			height: 100%;
			width: 100%;
		}
	}

	.map-loading-placeholder {
		display: none;
	}

	&.map-loading {
		.harbours-list {
			position: absolute;
			bottom: 0;
			width: 100%;
		}

		.google-map {
			background: url(/img/logo/splash/logo.png) 50% 50% no-repeat;
		}
	}
}

.menu-active {
	.locate-me {
		display: none;
	}
}