@account-edit-margin-top: 52px;

.account-edit {
	.account-edit-header {
		.zindex('account-edit-header');
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flex-display();
		.align-items(center);
		.flex-direction(row);
		.flex-wrap(nowrap);
		.justify-content(space-between);

		background: @mah-theme-color;
		color: @mah-white;
		min-height: @account-edit-margin-top;
		.shadowed(0.125em, @mah-grey-background, @mah-inactive-background-darker);

		.account-edit-header-left {
			.flex-display();
			.align-items(center);
			.flex-direction(row);
			.flex-wrap(nowrap);
		}

		a, a:active, a:hover, a:link {
			color: @mah-white;
			text-decoration: none;
			cursor: text;
		}

		.menu-button {
			background-color: @mah-theme-color;
			border: 0;
			padding: 0;
			margin: 0 14px;
			.flex-display(flex);
		}

		.account-edit-title {
			margin: 0 0 0 12px;
			font-weight: 400;
			font-size: 18px;
		}
	}

	.account-edit-form {
		.zindex('account-edit-form');
		position: absolute;
		top: @account-edit-margin-top;
		left: 0;
		width: 100%;
		height: ~"calc(100vh - @{account-edit-margin-top})";
		background: @mah-lighter-background;
		overflow-y: auto;
		.account-buttons {
			margin-top: 8px;
		}
	}
}